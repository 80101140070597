import { OrbitControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { Matrix4, MOUSE, Vector3 } from 'three';
import styles from '../../styles/CanvasControls.module.scss';

let _controls;

export default function CustomOrbitControls({ setOrbitControls }) {
  const { controls } = useThree();
  _controls = controls;

  useEffect(() => setOrbitControls(controls), [controls, setOrbitControls]);

  useFrame(() => {
    if (controls?.object) setCubeSettings(controls.object);
  });

  return (
    <OrbitControls
      makeDefault
      mouseButtons={{
        LEFT: MOUSE.DOLLY,
        MIDDLE: MOUSE.PAN,
        RIGHT: MOUSE.ROTATE
      }}
      enableZoom={false}
    />
  );
}

export function zoomToCursor(event) {
  if (!_controls) throw new Error('No CustomOrbitControls found!');

  const camera = _controls.object;

  if (camera.type === 'PerspectiveCamera') {
    const mX = (event.clientX / event.nativeEvent.target.offsetWidth) * 2 - 1;
    const mY = -(event.clientY / event.nativeEvent.target.offsetHeight) * 2 + 1;
    const vector = new Vector3(mX, mY, 0.5);
    vector.unproject(camera).sub(camera.position);

    const factor = event?.shiftKey ? 0.1 : 1;
    if (event.deltaY < 0) {
      camera.position.addVectors(camera.position, vector.setLength(factor));
    } else {
      camera.position.subVectors(camera.position, vector.setLength(factor));
    }

    const targetToCameraDistance = Math.max(factor, _controls.target.distanceTo(camera.position));
    const newTarget = camera.getWorldDirection(new Vector3(0, 0, 0)).setLength(targetToCameraDistance).add(camera.position);
    _controls.target = newTarget;
  } else {
    const minZoom = 0.01;
    const maxZoom = 500;
    const amount = event.deltaY / 100;
    const zoom = camera.zoom - amount;

    if (zoom >= minZoom && zoom <= maxZoom) {
      camera.zoom = zoom;
    }
  }

  camera.updateProjectionMatrix();
}

const setCubeSettings = (camera) => {
  const setTransform = (matrixWorldInverse) => {
    if (!matrixWorldInverse)
      return `translateZ(-300px) matrix3d(0.8346, 0.170384, 0.523844, 0, 0, -0.950962, 0.309307, 0, -0.550857, 0.258148, 0.793673, 0, 0, 0, 0, 1)`;

    const mat = new Matrix4();
    mat.extractRotation(matrixWorldInverse);
    return `translateZ(-300px) ${getCameraCSSMatrix(mat)}`;
  };
  const getCameraCSSMatrix = (matrix) => {
    const elements = matrix.elements;

    return (
      'matrix3d(' +
      epsilon(elements[0]) +
      ',' +
      epsilon(-elements[1]) +
      ',' +
      epsilon(elements[2]) +
      ',' +
      epsilon(elements[3]) +
      ',' +
      epsilon(elements[4]) +
      ',' +
      epsilon(-elements[5]) +
      ',' +
      epsilon(elements[6]) +
      ',' +
      epsilon(elements[7]) +
      ',' +
      epsilon(elements[8]) +
      ',' +
      epsilon(-elements[9]) +
      ',' +
      epsilon(elements[10]) +
      ',' +
      epsilon(elements[11]) +
      ',' +
      epsilon(elements[12]) +
      ',' +
      epsilon(-elements[13]) +
      ',' +
      epsilon(elements[14]) +
      ',' +
      epsilon(elements[15]) +
      ')'
    );
    function epsilon(value) {
      return Math.abs(value) < 1e-10 ? 0 : value;
    }
  };
  const setShadow = (quaternion) => {
    if (!quaternion) return 'block';

    const viewingAngle = new Vector3(0, 0, -1);
    viewingAngle.applyQuaternion(quaternion);

    if (viewingAngle.y > 0) return 'none';
    else return 'block';
  };

  if (document.getElementById(styles.rotateCube))
    document.getElementById(styles.rotateCube).style.transform = setTransform(camera.matrixWorldInverse);
  if (document.getElementById(styles.rotateCubeShadow))
    document.getElementById(styles.rotateCubeShadow).style.display = setShadow(camera.quaternion);
};
